@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:200);
body {
  font-family: 'Teko', sans-serif;
}

img {
  width: 175px;
  height: 195px;
  border-radius: 50%;
}

.h1 {
  align-text: center;
}

.fullName {
  color: #22311d;
  font-size: 60px;
}

.workTiles {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.workPieces {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  height: 250px;
}
h1 {
-webkit-align-self: start;
        align-self: start;
margin-left: 100px;
}

.workPieces > div {
  width: 25vw;
  height: 200px;
  border: solid white 2px;
  /* box-shadow: 5px 5px 10px #edebe4; */
  border-radius: 5%;
  font-size: 42px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100vw;
  margin: 0;
}

ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 30px;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  list-style-type: none;
  width: 30vw;
}


.workLinks {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 150px;
  margin: 20px;
}
.logo {
  width: 64px;
  height: 64px;
}

h1 {
  font-size: 40px;
}
.pageContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.skills {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 1000px;
}

.skills > div {
  border: solid white 2px;
  padding: 5px;
  margin: 20px;
  font-size: 30px;
}

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
  padding: 0;
  color: white;
}

body  {
    background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
  background-size:cover;
        -webkit-animation: slidein 100s;
        animation: slidein 100s;

        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;

        -webkit-animation-direction: alternate;
        animation-direction: alternate;              
}

@-webkit-keyframes slidein {
from {background-position: top; background-size:3000px; }
to {background-position: -100px 0px;background-size:2750px;}
}

@keyframes slidein {
from {background-position: top;background-size:3000px; }
to {background-position: -100px 0px;background-size:2750px;}

}



.center
{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: rgba(75, 75, 250, 0.3); */
  border-radius: 3px;
}
.center h1{
  text-align:center;
  font-family: 'Source Code Pro', monospace;
  text-transform:uppercase;
}

