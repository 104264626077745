.workTiles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.workPieces {
  display: flex;
  justify-content: space-around;
  width: 100vw;
  height: 250px;
}
h1 {
align-self: start;
margin-left: 100px;
}

.workPieces > div {
  width: 25vw;
  height: 200px;
  border: solid white 2px;
  /* box-shadow: 5px 5px 10px #edebe4; */
  border-radius: 5%;
  font-size: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
