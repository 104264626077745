.workLinks {
  display: flex;
  justify-content: space-around;
  width: 150px;
  margin: 20px;
}
.logo {
  width: 64px;
  height: 64px;
}
