.header {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  margin: 0;
}

ul {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  justify-content: space-around;
  list-style-type: none;
  width: 30vw;
}

