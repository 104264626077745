h1 {
  font-size: 40px;
}
.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.skills {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
}

.skills > div {
  border: solid white 2px;
  padding: 5px;
  margin: 20px;
  font-size: 30px;
}
